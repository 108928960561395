@keyframes pulseRing {
  0% {
    transform: scale(0.5);
  }

  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulseDot {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1);
  }
}

.you {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 2px solid white;
  animation: pulseDot 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  background-color: #1ba6fc;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  will-change: animation;
  z-index: 2 !important;
}

.you:before {
  animation: pulseRing 4s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  background-color: #1ba6fc;
  content: "";
  display: block;
  height: 300%;
  left: -100%;
  position: relative;
  top: -100%;
  width: 300%;
  border-radius: 45px;
  will-change: animation;
}
